
import { computed, defineComponent, onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import { useStore } from "vuex";

export default defineComponent({
  name: "FounderStep1",
  components: {
    Field,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const allProjectStatuses = computed(() => {
      return store.getters["UserModule/getAllProjectStatuses"];
    });

    onMounted(async () => {
      await store.dispatch("UserModule/fetchAllProjectStatuses");
    });

    return { allProjectStatuses };
  },
});
