<template>
  <div class="w-100">
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder text-dark">Business Info</h2>
      <div class="text-gray-400 fw-bold fs-6">
        If you need more info, please check out
        <a class="link-primary fw-bolder">Help Page</a>.
      </div>
    </div>
    <div class="mb-10 fv-row">
      <label class="form-label mb-3">Select Industry Verticals</label>
      <Field name="industry" v-slot="{ field }">
        <Multiselect
          valueProp="id"
          class="custom-styles multiselect-blue"
          v-bind="field"
          :options="industries"
          label="name"
          mode="tags"
          trackBy="name"
          :create-tag="true"
          :searchable="true"
        />
      </Field>
      <ErrorMessage
        name="industry"
        class="fv-plugins-message-container invalid-feedback"
      ></ErrorMessage>
    </div>
    <div class="mb-10 fv-row">
      <label class="form-label mb-3"
        >Which Customer Groups Do You Focus On?</label
      >
      <Field
        class="form-control form-control-lg form-control-solid"
        name="focusedCustomerGroups"
        v-slot="{ field }"
      >
        <Multiselect
          valueProp="id"
          class="custom-styles multiselect-blue"
          v-bind="field"
          :options="customerGroups"
          label="name"
          mode="tags"
          trackBy="name"
          :create-tag="true"
          :searchable="true"
        />
      </Field>
      <ErrorMessage
        name="focusedCustomerGroups"
        class="fv-plugins-message-container invalid-feedback"
      ></ErrorMessage>
    </div>
    <div class="mb-0 fv-row">
      <label class="form-label mb-3">Primary Business Model</label>
      <Field
        class="form-control form-control-lg form-control-solid"
        name="businessModel"
        v-slot="{ field }"
      >
        <Multiselect
          valueProp="id"
          class="custom-styles multiselect-blue"
          v-bind="field"
          :options="businessModels"
          label="name"
          mode="tags"
          trackBy="name"
          :create-tag="true"
          :searchable="true"
        />
      </Field>
      <ErrorMessage
        name="businessModel"
        class="fv-plugins-message-container invalid-feedback"
      ></ErrorMessage>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { useStore } from "vuex";

export default defineComponent({
  name: "FounderStep2",
  components: {
    Field,
    ErrorMessage,
    Multiselect,
  },
  setup() {
    const store = useStore();
    const businessModels = computed(() => {
      return store.getters["FounderModule/getBusinessModals"];
    });
    const industries = computed(() => {
      return store.getters["UserModule/getAllIndustries"];
    });
    const customerGroups = computed(() => {
      return store.getters["FounderModule/getCustomerGroups"];
    });

    onMounted(async () => {
      await store.dispatch("FounderModule/fetchBusinessModals");
      console.log("Business Models: ", businessModels.value);
      await store.dispatch("UserModule/fetchAllIndustries");
      console.log("industries: ", industries.value);
      await store.dispatch("FounderModule/fetchCustomerGroups");
      console.log("customer groups: ", customerGroups.value);
      sortData(businessModels);
      sortData(industries);
      sortData(customerGroups);
    });

    const sortData = (arr) => {
      arr.value.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    };

    return {
      businessModels,
      industries,
      customerGroups,
    };
  },
});
</script>
<style scoped>
.custom-styles {
  --ms-font-size: 1rem;
  --ms-line-height: 1.375;
  --ms-bg: #000000;
  --ms-bg-disabled: #f3f4f6;
  --ms-border-color: orange;
  --ms-border-width: 1px;
  --ms-radius: 4px;
  --ms-py: 0.5rem;
  --ms-px: 0.875rem;
  --ms-ring-width: 3px;
  --ms-ring-color: #10b98130;
  --ms-placeholder-color: red;
  --ms-max-height: 10rem;
}
.multiselect-blue {
  --ms-tag-bg: red;
  --ms-tag-color: green;
}

/*//.form-control.form-control-solid {*/
/*//  background-color: #f5f8fa;*/
/*//  border-color: #f5f8fa;*/
/*//  color: #5e6278;*/
/*//  transition: color 0.2s ease, background-color 0.2s ease;*/
/*//}*/
</style>
