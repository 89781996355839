
import { computed, defineComponent, onMounted, ref } from "vue";
import FounderStep1 from "@/user-journey/components/FounderStep1.vue";
import FounderStep2 from "@/user-journey/components/FounderStep2.vue";
import FounderStep3 from "@/user-journey/components/FounderStep3.vue";
import Step1 from "@/user-journey/components/Step1.vue";
import Step5 from "@/user-journey/components/Step5.vue";
import { StepperComponent } from "@/assets/ts/components";
import * as Yup from "yup";
import { useForm } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { createFounder } from "@/api/founder.api";
import { getAuth } from "firebase/auth";
import { useRouter } from "vue-router";
import { getUser } from "@/api/user.api.js";
import { useStore } from "vuex";

interface IStep1 {
  accountType: string;
}

interface IStep2 {
  companyName: string;
  website: string;
  projectStatus: string;
}

interface IStep3 {
  industry: string[];
  focusedCustomerGroups: string[];
  businessModel: string[];
}

interface IStep4 {
  fullTimeFounders: string;
  teamLocation: string;
  investorFunding: number;
}

interface CreateAccount extends IStep1, IStep2, IStep3, IStep4 {}

export default defineComponent({
  name: "FounderWizard",
  components: {
    Step1,
    FounderStep1,
    FounderStep2,
    FounderStep3,
    Step5,
  },
  async beforeRouteEnter(to, from, next) {
    const uuid = getAuth().currentUser?.uid;
    try {
      await getUser(uuid);
      await next("/dashboard");
    } catch (e) {
      next();
    }
  },
  setup() {
    const store = useStore();
    const _stepperObj = ref<StepperComponent | null>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(1);
    const router = useRouter();
    const submitButtonRef = ref<HTMLButtonElement | null>(null);

    const formData = ref<CreateAccount>({
      accountType: "founder",
      companyName: "",
      website: "",
      projectStatus: "",
      industry: [],
      focusedCustomerGroups: [],
      businessModel: [],
      fullTimeFounders: "",
      teamLocation: "",
      investorFunding: 0,
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        verticalWizardRef.value as HTMLElement
      );
      if (!_stepperObj.value) {
        return;
      }
      _stepperObj.value.goNext();
      setCurrentPageBreadcrumbs("Vertical", ["Pages", "Wizards"]);
    });

    const createAccountSchema = [
      Yup.object({
        accountType: Yup.string().required().label("Account Type"),
      }),
      Yup.object({
        companyName: Yup.string().required().label("Company Name"),
        projectStatus: Yup.string().required().label("Project Status"),
      }),
      Yup.object({
        industry: Yup.array()
          .required()
          .label("Industry")
          .min(1, "Select minimum one value"),
        focusedCustomerGroups: Yup.array()
          .required()
          .label("Customer Groups")
          .min(1, "Select minimum 1 value"),
        businessModel: Yup.array()
          .required()
          .label("Business Model")
          .min(1, "Select minimum 1 value"),
      }),
      Yup.object({
        fullTimeFounders: Yup.string().required().label("Founders"),
        teamLocation: Yup.string().required().label("Location"),
      }),
    ];

    const currentSchema = computed(() => {
      return createAccountSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<
      IStep1 | IStep2 | IStep3 | IStep4
    >({
      validationSchema: currentSchema,
    });

    const onChangeAccountType = (accountType) => {
      formData.value.accountType = accountType;
    };

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = async () => {
      const payload = {
        uuid: getAuth().currentUser?.uid,
        role: 1,
        companyName: formData.value.companyName,
        websiteLink: formData.value.website,
        projectStatusId: formData.value.projectStatus,
        industry: formData.value.industry,
        customerGroup: formData.value.focusedCustomerGroups,
        businessModel: formData.value.businessModel,
        activeFounders: formData.value.fullTimeFounders,
        location: formData.value.teamLocation,
      };
      try {
        if (submitButtonRef.value) {
          //Disable button
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        }
        const { data } = await createFounder(payload);
        await store.dispatch("UserModule/setUserId", data.data.user.id);
        await router.push("/pitch");
      } catch (e) {
        console.error(e);
      } finally {
        if (submitButtonRef.value) {
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        }
      }
    };

    return {
      verticalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      onChangeAccountType,
      totalSteps,
      currentStepIndex,
      submitButtonRef,
    };
  },
});
